<template>
  <master-layout :showContentHeader="false" :showHeader="false">
    <ion-header>
      <ion-row>
        <ion-col size="9" class="ion-padding ion-margin-start">
          <h4>
            {{ anagrafica ? anagrafica.ragioneSociale : "Nuovo Lead" }}
          </h4>
        </ion-col>
        <ion-col size="2" class="ion-align-items-center ion-margin-top">
          <ion-icon
            class="arrow-down"
            @click="closeModal"
            :icon="arrowDownOutline"
          ></ion-icon>
        </ion-col>
      </ion-row>
    </ion-header>

    <ion-grid>
      <div class="w-full" v-if="!anagrafica">
        <ion-row>
          <ion-col>
            <ion-item lines="none">
              <ion-input v-model="pIvaCF" placeholder="P.Iva "> </ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col size="auto">
            <button class="p-d button-primary" @click="checkPIva">
              {{ loading ? "Caricamento..." : "Cerca" }}
            </button>
          </ion-col>
        </ion-row>
      </div>

      <!--output se esiste il lead-->

      <!-- <ion-alert
        :is-open="exists"
        header="Lead Esistente"
        message="Esiste già una lead con questo codice"
        css-class="my-custom-class"
        :buttons="buttons"
        @didDismiss="setOpen(false)"
      >
      </ion-alert> -->

      <!--Form per nuova lead-->
      <div v-if="loading">Caricamento in corso...</div>
      <div v-else>
        <!-- <ion-row v-if="loading">
          <ion-col size="auto" class="ion-padding-top">
            <h3>Ricerca in corso...</h3>
          </ion-col>
        </ion-row> -->

        <ion-row v-if="exists" class="ion-justify-content-center">
          <ion-col size="auto" class="ion-padding-top">
            <h3>Anagrafica già esistente</h3>
          </ion-col>
        </ion-row>

        <ion-row v-if="(checked && !exists) || anagrafica">
          <ion-col>
            <ion-row>
              <ion-col>
                <ion-item lines="none" v-if="aziende?.lenght > 0">
                  <ion-label>Azienda</ion-label>
                  <ion-select>
                    <ion-select-option
                      v-model="aziendaSelezionata"
                      v-for="azienda of aziende"
                      :key="azienda.id"
                      >{{ azienda.name }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
                <ion-item lines="none">
                  <ion-input v-model="nome" placeholder="Nome"></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="cognome"
                    placeholder="Cognome"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="ragioneSociale"
                    placeholder="Ragione Sociale"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input v-model="email" placeholder="Email"></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input v-model="pec" placeholder="PEC"></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="telefono"
                    placeholder="Telefono"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="cellulare"
                    placeholder="Cellulare"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="partitaIva"
                    placeholder="Partita IVA"
                    :disabled="anagrafica.idPadre ? true : false"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="codiceFiscale"
                    placeholder="Codice Fiscale"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="indirizzo"
                    placeholder="Indirizzo"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input v-model="citta" placeholder="Città"></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="nazione"
                    placeholder="Nazione"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-select
                    label="Regione"
                    v-model="regione"
                    placeholder="Regione"
                  >
                    <ion-select-option
                      v-for="r of regioniOptions"
                      :key="r"
                      :value="r"
                      >{{ r }}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
                <ion-item lines="none">
                  <ion-select
                    label="Provincia"
                    v-model="provincia"
                    placeholder="Provincia"
                  >
                    <ion-select-option
                      v-for="p of provinciaOptions"
                      :key="p"
                      :value="p"
                      >{{ p }}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="sedeLegale"
                    placeholder="Sede legale"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="indirizzoDiConsegna"
                    placeholder="Indirizzo di consegna"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="bancaDAppoggio"
                    placeholder="Banca d'appoggio"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="gruppoDAquisto"
                    placeholder="Gruppo d'acquisto"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="referenteCommerciale"
                    placeholder="Referente commerciale"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="referenteAmministrazione"
                    placeholder="Referente amministrazione"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="ABIeCAB"
                    placeholder="ABIeCAB"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-input
                    v-model="codiceUnivocoSDI"
                    placeholder="Codice univoco SDI"
                  ></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <ion-textarea
                    v-model="nota"
                    placeholder="Scrivi nota..."
                  ></ion-textarea>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>

        <ion-row
          v-if="((checked && !exists) || anagrafica) && !loading"
          class="cta ion-justify-content-center"
        >
          <ion-col size="auto">
            <small
              class="ion-padding ion-margin-bottom text-danger ion-text-center"
              style="color: brown"
              v-if="!validForm"
            >
              Selezionare la regione e inserire una email valida
            </small>
            <button v-else class="p-d button-primary" @click="addAnagrafica">
              {{ anagrafica ? "Salva" : "Aggiungi" }}
            </button>
          </ion-col>
        </ion-row>
        <ion-row class="cta ion-justify-content-center">
          <ion-col size="auto">
            <button class="p-d button-primary" @click="closeModal">
              Close
            </button>
          </ion-col>
        </ion-row>
      </div>
    </ion-grid>
  </master-layout>
</template>

<script>
import {
  IonAlert,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonCard,
  modalController,
} from "@ionic/vue";
import { arrowDownOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import ApiService from "@/common/service.api.js";
import trCard from "../../components/Nuovi/trCard.vue";
import ShoppingCartService from "../../common/mixins/ShoppingCart.vue";

export default defineComponent({
  components: {
    IonAlert,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonList,
    IonItem,
    IonTextarea,
    IonCard,
    trCard,
  },
  name: "ModalNewAzienda",
  props: {
    aziende: Array,
    anagrafica: {
      type: Object,
      required: false,
    },
  },
  mixins: [ShoppingCartService],
  data() {
    return {
      loading: false,
      arrowDownOutline,
      checked: false,
      leads: [],
      pIvaCF: "",
      exists: null,
      buttons: ["Ok"],
      leadEsistente: null,
      aziendaSelezionata: "",
      nome: "",
      cognome: "",
      ragioneSociale: "",
      email: "",
      pec: "",
      telefono: "",
      cellulare: "",
      partitaIva: "",
      codiceFiscale: "",
      indirizzo: "",
      citta: "",
      provincia: "",
      regione: "",
      nazione: "",
      sedeLegale: "",
      indirizzoDiConsegna: "",
      bancaDAppoggio: "",
      gruppoDAquisto: "",
      referenteCommerciale: "",
      referenteAmministrazione: "",
      ABIeCAB: "",
      codiceUnivocoSDI: "",
      nota: "",
      regioniProvinceLoading: false,
      regioniProvinceList: [],
      regioniOptions: [],
    };
  },

  created() {
    console.log("CREATED ", this.anagrafica);

    if (this.anagrafica) {
      this.populateModalWithAnagrafica();
    }

    this.getRegioniProvince();
  },
  computed: {
    provinciaOptions() {
      return this.regioniProvinceList
        .filter((rp) => rp.regione === this.regione)
        .map((rp) => rp.provincia);
    },
    validForm() {
      if (this.regione.length === 0) {
        return false;
      }
      return true;
    },
  },
  watch: {
    exists(newVal, oldVal) {
      console.log("Exists changed from", oldVal, "to", newVal);
    },
  },

  methods: {
    async getRegioniProvince() {
      this.regioniProvinceLoading = true;
      this.regioniProvinceList.splice(0);
      this.regioniOptions.splice(0);
      try {
        const regioniProvinceList = await ApiService.get(
          "Ecommerce/GetRegioniProvince"
        );
        this.regioniProvinceList = regioniProvinceList.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.regioniOptions = [
          ...new Set(this.regioniProvinceList.map((x) => x.regione)),
        ];
        this.regioniProvinceLoading = false;
      }
    },
    populateModalWithAnagrafica() {
      this.nome = this.anagrafica.nome;
      this.cognome = this.anagrafica.cognome;
      this.ragioneSociale = this.anagrafica.ragioneSociale;
      this.email = this.anagrafica.email;
      this.pec = this.anagrafica.pec;
      this.telefono = this.anagrafica.telefono;
      this.cellulare = this.anagrafica.cellulare;
      this.partitaIva = this.anagrafica.partitaIva;
      this.codiceFiscale = this.anagrafica.codiceFiscale;
      this.indirizzo = this.anagrafica.indirizzo;
      this.citta = this.anagrafica.citta;
      this.provincia = this.anagrafica.provincia;
      this.regione = this.anagrafica.regione;
      this.nazione = this.anagrafica.nazione;
      this.sedeLegale = this.anagrafica.sedeLegale;
      this.indirizzoDiConsegna = this.anagrafica.indirizzoDiConsegna;
      this.bancaDAppoggio = this.anagrafica.bancaDAppoggio;
      this.gruppoDAquisto = this.anagrafica.gruppoDAquisto;
      this.referenteCommerciale = this.anagrafica.referenteCommerciale;
      this.referenteAmministrazione = this.anagrafica.referenteAmministrazione;
      this.ABIeCAB = this.anagrafica.ABIeCAB;
      this.codiceUnivocoSDI = this.anagrafica.codiceUnivocoSDI;
      this.nota = this.anagrafica.nota;
    },
    gotoDettagliCliente(cliente) {
      this.closeModal();

      this.$router.push({
        name: "DettagliCliente",
        params: {
          idCliente: cliente.id,
        },
      });
    },
    addAnagrafica() {
      const formData = {
        anagraficaYoube: true,
        aziendaSelezionata: this.aziendaSelezionata,
        aziende: this.aziende,
        nome: this.nome,
        cognome: this.cognome,
        ragioneSociale: this.ragioneSociale,
        email: this.email,
        pec: this.pec,
        telefono: this.telefono,
        cellulare: this.cellulare,
        partitaIva: this.partitaIva,
        codiceFiscale: this.codiceFiscale,
        indirizzo: this.indirizzo,
        citta: this.citta,
        provincia: this.provincia,
        regione: this.regione,
        nazione: this.nazione,
        sedeLegale: this.sedeLegale,
        indirizzoDiConsegna: this.indirizzoDiConsegna,
        bancaDAppoggio: this.bancaDAppoggio,
        gruppoDAquisto: this.gruppoDAquisto,
        referenteCommerciale: this.referenteCommerciale,
        referenteAmministrazione: this.referenteAmministrazione,
        ABIeCAB: this.ABIeCAB,
        codiceUnivocoSDI: this.codiceUnivocoSDI,
        nota: this.nota,
        idPadre: this.anagrafica ? this.anagrafica.idPadre : 0,
        idTipoAnagrafica: 3,
      };

      if (this.anagrafica && this.anagrafica.id) {
        ApiService.put(
          `Ecommerce/EditAnag/${this.anagrafica.id}`,
          formData
        ).then(() => {
          this.closeModal();
        });
      } else {
        let idNuovaAnagrafica;
        ApiService.post(`Ecommerce/AddAnag`, formData)
          .then((res) => {
            console.log(res);
            this.closeModal();
            idNuovaAnagrafica = res.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.closeModal();
            this.$router.push({
              name: "DettagliCliente",
              params: {
                idCliente: idNuovaAnagrafica,
              },
            });
          });
      }
      this.setCustomer(formData);
    },
    closeModal() {
      return modalController.dismiss();
    },
    async checkPIva() {
      this.loading = true;
      if (!this.pIvaCF) return;
      this.checked = true;

      await this.getAnagsByPartitaIva(this.pIvaCF);

      let result = this.leads.filter((element) => {
        return (
          element.pIva === this.pIvaCF || element.partitaIva === this.pIvaCF
        );
      });

      this.leadEsistente = result;

      if (result.length > 0) {
        this.exists = true;
        console.log("exists set to true: ", this.exists);
      } else {
        this.partitaIva = this.pIvaCF;
        this.exists = false;
        console.log("exists set to false: ", this.exists);
      }

      this.loading = false;
    },

    async getAnagsByPartitaIva(partitaIva) {
      this.loading = true;
      await ApiService.post(`Ecommerce/GetAnagsAll/`, {
        id: 0,
        idTipoAnagrafica: 3,
        idTipoAzienda: 0,
        partitaIva: partitaIva,
        azienda: "",
        email: "",
        cellulare: "",
        pec: "",
        nome: "",
        cognome: "",
        ragioneSociale: "",
        codiceFiscale: "",
        indirizzo: "",
        citta: "",
        provincia: "",
        regione: "",
        nazione: "",
        indirizzoDiConsegna: "",
        sedeLegale: "",
        bancaDAppoggio: "",
        gruppoDAquisto: "",
        referenteCommerciale: "",
        referenteAmministrazione: "",
        ABIeCAB: "",
        codiceUnivocoSDI: "",
        nota: "",
      })
        .then((res) => {
          console.log(res.data);
          this.leads = res.data;

          let result = this.leads.filter((element) => {
            return (
              element.pIva === this.pIvaCF || element.partitaIva === this.pIvaCF
            );
          });

          if (result) {
            this.exists = true;
            this.populateModalWithAnagrafica();
          } else {
            this.exists = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>

<style scoped>
/* * {
  border: 1px solid greenyellow;
} */

ion-grid {
  width: 90%;
  margin: auto;
}

ion-item {
  margin: 1rem 0;
  border: 1px solid var(--text-color-light);
  border-radius: var(--ion-border-radius);
}

ion-icon {
  font-size: 2rem;
  margin-bottom: 8px;
  color: rgb(141, 141, 141);
}

.arrow-down {
  margin: 8px;
}

.cta {
  padding: 2rem 0 3rem 0;
}
</style>
